import React from "react";
import { Navbar } from "../../tailwind";
import { Footer } from "../../tailwind/Footer";

export default () => (
  <>
    <Navbar />
    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
      <div className="bg-white rounded border shadow mt-10 p-6">
        <h1 className="text-2xl text-gray-800 font-semibold py-1">SR-22 and FR-44 Insurance</h1>
        <hr className="py-1"/>  
        <p className="text-gray-700 text-sm">People who are required to file an SR-22 or an SR-44 usually have questions
        about what exactly these terms mean, what is involved in filing them, how much these certificates will cost
        them,
        and how long the certificates will stay on file. Here is an overview of the forms and some answers to common
        questions regarding filing these documents to help ensure compliance with the law.</p><h2 className="text-lg text-gray-800 font-semibold py-2">SR-22 Insurance
        Basics</h2><p className="text-gray-700 text-sm">Though it's usually referred to as SR-22 insurance, an SR-22 form is not actually an insurance
        policy.
        Instead, it is a certificate of financial responsibility that verifies that a particular individual has
        insurance
        coverage. An insurance company files this certificate with the state. It certifies that the individual has the
        amount of liability coverage that the state requires that person to have. When a person is legally obligated to
        file
        an SR-22, a judge will usually tell the individual. In some cases, the state licensing agency provides the
        notification. Being required to file is usually related to traffic violations of various types. The most common
        violations include:</p>
        <ul>
          <li className="text-gray-700 text-sm">DUI/ DWI</li>
          <li className="text-gray-700 text-sm">Driving without insurance</li>
          <li className="text-gray-700 text-sm">Driving on a suspended license</li>
          <li className="text-gray-700 text-sm">Driving on a revoked license</li>
        </ul>
        <h2 className="text-lg text-gray-800 font-semibold py-2">SR-44 Insurance Basics</h2><p className="text-gray-700 text-sm">SR-44 is similar to an SR-22, but only two states use the SR-44 certificate:
        Florida and Virginia. Like an SR-22, an SR-44 is not an insurance policy. It is a certificate proving that the
        filer
        has the legally required amount of insurance coverage.</p><h3>The filing process</h3><p className="text-gray-700 text-sm">For a person who already
        has
        car insurance, filing these certificates requires notifying the insurance company to make sure the company is
        authorized to file these forms. If the insurer is not able to, they will usually recommend a different insurer.
        The
        insurance company takes care of the actual filing. Many insurance companies will require the person being
        insured to
        pay for the full term of the policy when they file one of these forms. This is to eliminate the risk of
        cancellation
        due to nonpayment. Filing both types of certificates involves the same process.</p><h3>Filing costs</h3>
        <p className="text-gray-700 text-sm">Filing
          either certificate is relatively inexpensive. The fee associated with filing an SR-22 is normally around $25.
          However, insurance rates are likely to increase as a result of filing either form. A person who already had
          insurance will notice an increased premium. A person getting insurance at the time of filing can expect to pay
          a
          higher premium than other drivers. The premiums will remain higher until the certificate is no longer on file.
          Insurers usually require the premium to be paid in full. They are not likely to accept monthly payments from
          someone
          who is filing an SR-22.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Common questions</h2>
        <ul className="list-disc list-inside">
          <li className="text-gray-700 text-sm">How long does an SR-22 stay on file? An SR-22 typically stays on file for two to three years depending on
            which state is requiring it. After the time period designated by the state, removing it usually just
            requires a
            phone call to the insurance provider.
          </li>
          <li className="text-gray-700 text-sm">Can SR-22 be canceled? Because most insurance companies require the entire term policy to be paid upfront
            when
            filing an SR-22, cancellation is usually not possible. In the event that an SR-22 is canceled, however, the
            insurer is legally required to notify the state. This can result in a drivers' license being suspended or
            revoked, so it is important not to cancel the SR-22.
          </li>
          <li className="text-gray-700 text-sm">How is SR-44 different from SR-22? SR-44 has stricter requirements than SR-22. An SR-44 requires the
            insured
            to have more than the state mandated minimum amount of liability insurance. In some cases, the amount
            required
            is substantially higher than the legal minimums. Virginia and Florida are the only states that use this
            particular certificate.
          </li>
          <li className="text-gray-700 text-sm">Will rates ever return to normal? Because the records of these documents only stay on file for a limited
            time,
            it is possible to return to normal insurance rates within a few years of filing an SR-22 or an SR-44. As
            long as
            no new traffic violations occur, a person can expect to pay the same insurance premiums as a person who has
            never had to file one of these certificates once it has been removed.
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </>
);